import React from 'react';
import { graphql } from 'gatsby';
import MetaData from '../../../components/Layout/MetaData.jsx';
import Footer from '../../../components/Footer/Footer';
import HeroBannerSection from '../../../components/LegacyPage/HeroBannerSection';
import styles from '../iwocaPage.module.scss';
import NavBar from '../../../components/NavBar/NavBar';

const IntroducerPage = ({ data }) => {
    const { contentfulPageIntroducer: pageData } = data;
    const {
        title,
        metaDescription,
        allowSearchEngineScraping = true,
        url
    } = pageData;

    // Object is in replacement of header data having to come from legacy page in contentful
    const headerBannerData = {
        headline: pageData.heroBusinessOwnerTitle,
        subtitleText: { subtitle: pageData.heroBusinessOwnerSubtitle },
        content: [{ image: pageData.heroBusinessOwnerImage }],
        button: pageData.heroCtaButtons
    };

    const loginButton = {
        text: 'Login',
        href: '/introducer/login/'
    };

    return (
        <div className={styles.Page}>
            <MetaData
                metaData={{
                    titleTag: title,
                    metaDescription,
                    allowSearchEngineScraping,
                    url
                }}
            />

            <NavBar
                hidePhoneNumber={true}
                hideLoginLink={true}
                ctaButton={loginButton}
            />

            <HeroBannerSection data={headerBannerData} />

            <Footer hideCta={true} hidePhoneNumber={true} />
        </div>
    );
};

export default IntroducerPage;

export const query = graphql`
    query IntroducerPageQuery($id: String!) {
        contentfulPageIntroducer(id: { eq: $id }) {
            title
            url
            metaDescription
            allowSearchEngineScraping
            heroBusinessOwnerTitle
            heroBusinessOwnerSubtitle
            heroCtaButtons {
                id
                buttonText
                url
            }
            heroBusinessOwnerImage {
                fixed(width: 1800, quality: 90) {
                    ...GatsbyContentfulFixed
                }
            }
        }
    }
`;
